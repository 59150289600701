import { getDefaultMiddleware } from '@reduxjs/toolkit'

import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist'
import storage from 'redux-persist/lib/storage'

// It's a method from persist docs for hiddening deprecated errors
const middleware = [
  ...getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  }),
]

// Here we write config for persist.
const authPersistConfig = {
  key: 'token',
  storage,
  whitelist: ['token'],
}

export { persistStore, persistReducer, authPersistConfig, middleware }
