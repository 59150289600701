import React from 'react'
import PropTypes from 'prop-types'

import { StyledInput, StyledInputBox, StyledIconBox } from './StyledInput'

function Input({
  placeholder,
  type,
  width,
  maxWidth,
  height,
  name,
  onChange,
  value,
  required,
  min,
  step,
  icon,
}) {
  return (
    <StyledInputBox width={width}>
      <StyledInput
        onChange={onChange}
        value={value}
        name={name}
        width={width}
        maxWidth={maxWidth}
        height={height}
        type={type}
        min={type === 'number' ? min : ''}
        minLength={min}
        step={step}
        placeholder={placeholder}
        required={required}
        icon={icon}
      />
      <StyledIconBox>{icon}</StyledIconBox>
    </StyledInputBox>
  )
}

Input.propTypes = {
  placeholder: PropTypes.string,
  type: PropTypes.oneOf(['email', 'password', 'text', 'number']),
  width: PropTypes.string,
  maxWidth: PropTypes.string,
  height: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  required: PropTypes.bool,
  min: PropTypes.number,
  step: PropTypes.number,
  icon: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
}

export default Input
