import React from 'react';

import SvgBox from './SvgBox';

export default function Licenses() {
  return (
    <SvgBox width="12px" height="15px">
      <svg
        width="12"
        height="15"
        viewBox="0 0 12 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.83341 0.837891C10.2135 0.837844 10.5794 0.982088 10.8572 1.24147C11.1351 1.50085 11.3041 1.85603 11.3301 2.23522L11.3334 2.33789V12.6699C11.3335 13.05 11.1892 13.4159 10.9298 13.6937C10.6705 13.9715 10.3153 14.1405 9.93608 14.1666L9.83341 14.1699H2.16675C1.78666 14.1699 1.42073 14.0257 1.14291 13.7663C0.865091 13.5069 0.696096 13.1518 0.670081 12.7726L0.666748 12.6699V2.33789C0.666701 1.95781 0.810945 1.59188 1.07033 1.31405C1.32971 1.03623 1.68489 0.867239 2.06408 0.841224L2.16675 0.837891H9.83341ZM3.16675 4.16656C3.03414 4.16656 2.90696 4.21924 2.81319 4.313C2.71943 4.40677 2.66675 4.53395 2.66675 4.66656C2.66675 4.79917 2.71943 4.92634 2.81319 5.02011C2.90696 5.11388 3.03414 5.16656 3.16675 5.16656H8.83341C8.96602 5.16656 9.0932 5.11388 9.18697 5.02011C9.28074 4.92634 9.33341 4.79917 9.33341 4.66656C9.33341 4.53395 9.28074 4.40677 9.18697 4.313C9.0932 4.21924 8.96602 4.16656 8.83341 4.16656H3.16675ZM2.66675 7.33322C2.66675 7.60922 2.89075 7.83322 3.16675 7.83322H8.83341C8.96602 7.83322 9.0932 7.78055 9.18697 7.68678C9.28074 7.59301 9.33341 7.46583 9.33341 7.33322C9.33341 7.20062 9.28074 7.07344 9.18697 6.97967C9.0932 6.8859 8.96602 6.83322 8.83341 6.83322H3.16675C3.03414 6.83322 2.90696 6.8859 2.81319 6.97967C2.71943 7.07344 2.66675 7.20062 2.66675 7.33322ZM3.16675 9.49989C3.03414 9.49989 2.90696 9.55257 2.81319 9.64634C2.71943 9.74011 2.66675 9.86728 2.66675 9.99989C2.66675 10.1325 2.71943 10.2597 2.81319 10.3534C2.90696 10.4472 3.03414 10.4999 3.16675 10.4999H8.83341C8.96602 10.4999 9.0932 10.4472 9.18697 10.3534C9.28074 10.2597 9.33341 10.1325 9.33341 9.99989C9.33341 9.86728 9.28074 9.74011 9.18697 9.64634C9.0932 9.55257 8.96602 9.49989 8.83341 9.49989H3.16675Z"
          fill="#004AAD"
        />
      </svg>
    </SvgBox>
  );
}
