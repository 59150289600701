import React from 'react'
import ReactDOM from 'react-dom'

// deffault method for using React-Router
import { BrowserRouter } from 'react-router-dom'
// deffault method for using Redux
import { Provider } from 'react-redux'
// method for preloading store from local-storage before App start
import { PersistGate } from 'redux-persist/integration/react'
// import store (redux-store) and persistor (preloaded store from local-storage)
import { store, persistor } from './redux/store'

import './styles/index.css'
import App from './App'

ReactDOM.render(
  // Order of connecting this methods is important. Don't change it.
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
)
